import {translate} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {UserTitle} from 'api/entities';

export const UserTitleLabels: Record<UserTitle, string> = {
  get [UserTitle.NONE]() {
    return translate(marker('USER_TITLE.NONE'));
  },
  get [UserTitle.MR]() {
    return translate(marker('USER_TITLE.MR'));
  },
  get [UserTitle.MRS]() {
    return translate(marker('USER_TITLE.MRS'));
  }
};
