<div class="center-container">
  <h2 class="mat-headline-6">{{ "REPLACE_DEVICE" | transloco }}</h2>
  <p>{{ "SELECT_DEVICE_TO_REPLACE" | transloco }}</p>
  <form #replaceDeviceForm="ngForm" class="layout-column" name="replaceDeviceForm">
    <mat-form-field appSelect>
      <mat-select [(ngModel)]="replacedLocationId" name="replacedLocationId" required>
        @for (device of devices; track device) {
          <mat-option [value]="device.id">{{ device.name }} [{{ device.serialNumber }}]</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="action-buttons ok-cancel">
      <button mat-flat-button class="cancel" routerLink="../../../confirmClient" type="button">
        <span>{{ "BACK" | transloco }}</span>
      </button>
      <button
        [disabled]="!replaceDeviceForm.valid"
        [routerLink]="[replacedLocationId, 'confirm']"
        class="ok"
        color="primary"
        mat-flat-button
        type="submit">
        <span>{{ "REPLACE_DEVICE" | transloco }}</span>
      </button>
    </div>
  </form>
  <ng-container [cgBusy]="loading$" />
</div>
