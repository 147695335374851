import {HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';

const CACHE = {};

export function invalidateCache(url: string | RegExp, except?: string): void {
  if (typeof url === 'string') {
    delete CACHE[url];
  } else if (url instanceof RegExp) {
    for (const key of Object.keys(CACHE)) {
      if (url.test(key) && key !== except) {
        delete CACHE[key];
      }
    }
  }
}

export function invalidateCacheByUrlAndBody(url: string, body: any): void {
  const urlWithBody = url + JSON.stringify(body);
  invalidateCache(urlWithBody);
}

export function isCached(url: string): boolean {
  return CACHE.hasOwnProperty(url);
}

// eslint-disable-next-line complexity
export function cachingInterceptor(request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> {
  if (request.method !== 'GET' && request.headers.get('x-cache') && !request.headers.get('behaveAsGET')) {
    throw new Error(
      'Request ' +
        request.urlWithParams +
        ' failed. ' +
        '"behaveAsGET" parameter must be set to true if the "getCache" parameter from getCacheHeaders() is set to true/false' +
        ' when performing a POST/PUT/DELETE request'
    );
  }

  const requestMethod = request.headers.get('behaveAsGET') ? 'GET' : request.method;
  if (requestMethod !== 'GET') {
    return handle(request);
  }
  let url = request.urlWithParams;
  if (request.body) {
    url += JSON.stringify(request.body);
  }
  const cachedResponse = CACHE[url] || null;
  if (cachedResponse instanceof HttpResponse && request.headers.get('x-cache') !== 'false') {
    return of(cachedResponse);
  } else if (cachedResponse instanceof Observable) {
    return cachedResponse;
  } else if (!cachedResponse && request.headers.get('x-cache') === 'true') {
    return EMPTY;
  }

  return (CACHE[url] = handle(request).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        CACHE[url] = event;
      }
    }),
    catchError(err => {
      CACHE[url] = undefined;
      return throwError(err);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  ));
}
