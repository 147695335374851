<div class="center-container">
  <h3>{{ "DEVICE_REGISTRATION" | transloco }}</h3>
  <p [innerHTML]="'CURRENT_LOGIN' | transloco: {name: Alcedo7User.currentUser.name}"></p>
  <mat-form-field appSelect [appSelectLabel]="'CLIENT' | transloco">
    <mat-select [(value)]="clientId" name="clientId">
      @for (client of Alcedo7User.clients; track client) {
        <mat-option [value]="client.id">{{ client.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="replaceDevice" class="replace-device-checkbox">
    <span>{{ "REPLACE_EXISTING_DEVICE" | transloco }}</span>
  </mat-checkbox>
  <div class="action-buttons">
    <button (click)="next()" color="primary" mat-flat-button>
      @if (replaceDevice) {
        <span>{{ "CONTINUE" | transloco }}</span>
      } @else {
        <span>{{ "REGISTER" | transloco }}</span>
      }
    </button>
  </div>
  <p class="log-out-message">{{ "MESSAGE_CHANGE_CLIENT" | transloco }}</p>
  <div class="action-buttons">
    <button (click)="logout()" mat-flat-button>
      <span>{{ "SIGN_OUT" | transloco }}</span>
    </button>
  </div>
  <ng-container [cgBusy]="loading$" />
</div>
