<div class="center-container">
  <h2 class="mat-headline-6">{{ "NAME_YOUR_DEVICE" | transloco }}</h2>
  <app-form-field>
    <input [(ngModel)]="deviceName" autofocus appInput name="deviceName" type="text" />
  </app-form-field>
  <button (click)="next()" color="primary" mat-flat-button>
    <span>{{ "OK" | transloco }}</span>
  </button>
  <ng-container [cgBusy]="saving$" />
</div>
