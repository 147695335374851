import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {importProvidersFrom} from '@angular/core';
import {MAT_SELECT_CONFIG, MatSelectConfig} from '@angular/material/select';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter, withInMemoryScrolling, withRouterConfig} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {provideTransloco, translocoConfig} from '@jsverse/transloco';
import {authInterceptor, provideAuth} from 'angular-auth-oidc-client';
import {CgBusyModule} from 'angular-busy2';
import {ApolloModule} from 'apollo-angular';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ToastrModule} from 'ngx-toastr';
import {apiVersion} from '../../alcedo/src/app/api-version.constant';
import {ApolloGraphQLProvider} from '../../alcedo/src/app/apollo-graphql.provider';
import {cachingInterceptor} from '../../alcedo/src/app/core/interceptors/caching.interceptor';
import {IotUserLanguages} from '../../alcedo/src/app/shared/properties/user/user-languages.enum';
import {AppComponent} from './app/app.component';
import {routes} from './app/app.routes';
import {errorInterceptor} from './app/core/error.interceptor';
import {TranslocoHttpLoader} from './app/transloco-loader.service';
import {environment} from './environments/environment';

function getDefaultLang(): string {
  const locale = navigator.languages !== undefined && navigator.languages[0] ? navigator.languages[0] : navigator.language;
  const language = locale.split('-')[0].toLowerCase();
  return Object.values(IotUserLanguages).includes(language as IotUserLanguages) ? language : IotUserLanguages.ENGLISH;
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor(), errorInterceptor, cachingInterceptor])),
    provideRouter(
      routes,
      withRouterConfig({paramsInheritanceStrategy: 'always'}),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    ),
    provideAuth({
      config: {
        authority: '/auth/realms/avelon',
        triggerAuthorizationResultEvent: true,
        redirectUrl: environment.production ? '/' : window.location.origin + '/',
        postLogoutRedirectUri: environment.production ? '/' : window.location.origin,
        clientId: 'avelon-iot-front-end',
        scope: 'openid offline_access',
        responseType: 'code',
        silentRenew: true,
        // logLevel: 1, // used for debug
        ignoreNonceAfterRefresh: true,
        issValidationOff: !environment.production,
        useRefreshToken: true,
        autoUserInfo: false,
        renewTimeBeforeTokenExpiresInSeconds: 60,
        ngswBypass: true,
        secureRoutes: [apiVersion, 'graphql/']
      }
    }),
    importProvidersFrom(
      CgBusyModule.forRoot(),
      ApolloModule,
      NgxPermissionsModule.forRoot(),
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
        maxOpened: 2,
        timeOut: 10000,
        extendedTimeOut: 5000
      })
    ),
    provideServiceWorker('ngsw-worker.js', {enabled: environment.production}),
    ApolloGraphQLProvider,
    provideTransloco({
      config: translocoConfig({
        availableLangs: Object.values(IotUserLanguages),
        defaultLang: getDefaultLang(),
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: {
          aot: environment.production
        }
      }),
      loader: TranslocoHttpLoader
    }),
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {hideSingleSelectionIndicator: true} as MatSelectConfig
    }
  ]
}).catch(err => console.error(err));
