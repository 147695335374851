import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';

export class ErrorInterceptorHandles {
  constructor(
    private toastrService: ToastrService,
    private errorMessage: Observable<string>
  ) {}

  handleResponseTypeBlob(response: any): void {
    const self = this;
    const file = new FileReader();
    file.readAsText(response.error);
    file.onload = () => {
      response.data = JSON.parse(file.result as string);
      self.handleErrorMessage(response);
    };
  }

  handleErrorMessage(response: any): void {
    const toastMessage = response.error ? response.error.message || response.statusText : response.statusText;
    this.closeDuplicateToast(toastMessage);
    this.errorMessage.subscribe(t => this.toastrService.error(toastMessage, t));
  }

  private closeDuplicateToast(message: string) {
    if (this.toastrService.currentlyActive) {
      this.toastrService.toasts.filter(t => t.message === message).forEach(t => this.toastrService.remove(t.toastId));
    }
  }
}
