import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Device} from '../../core/services/device.interface';
import {DeviceService} from '../../core/services/device.service';
import {IoTService} from '../../core/services/iot.service';
import {AppFormFieldComponent} from '../shared/app-form-field/app-form-field.component';
import {AppInputDirective} from '../shared/app-form-field/app-input.directive';

@Component({
  selector: 'app-device-change-name',
  templateUrl: './device-change-name.component.html',
  styleUrls: ['./device-change-name.component.scss'],
  standalone: true,
  imports: [AppFormFieldComponent, AppInputDirective, FormsModule, MatButtonModule, CgBusyDirective, TranslocoModule]
})
export class DeviceChangeNameComponent implements OnInit {
  deviceName: string;
  device: Device;
  saving$: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceService,
    private iotService: IoTService
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          return this.iotService.loadDevices().pipe(map(() => params));
        })
      )
      .subscribe((params: Params) => {
        this.device = this.iotService.getDeviceByActivationCode(params.activationCode);
        this.deviceName = this.device.name;
      });
  }

  next() {
    this.saving$ = this.deviceService
      .updateDevice(
        {
          name: this.deviceName,
          dtype: this.device.dtype
        },
        this.device.id
      )
      .subscribe(() => {
        this.device.name = this.deviceName;
        this.deviceService.getDevice(this.activatedRoute.snapshot.params.activationCode).subscribe(device => (device.name = this.deviceName));
        if (location.pathname.startsWith('/activate')) {
          this.router.navigate(['../real-estate-ask'], {relativeTo: this.activatedRoute});
        } else {
          this.router.navigate(['../info'], {relativeTo: this.activatedRoute});
        }
      });
  }
}
